import React from "react";
import { Container, styled } from "@mui/material";
import {graphql} from "gatsby";


const Title = styled("p")`
  color: #0e72d7;
  font-weight: 800;
  line-height: calc(1.8rem + (10 * (100vw - 400px) / 624));
  font-size: calc(1.6rem + (5 * (100vw - 400px) / 624));
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const TitleRed = styled(Title)`
  color: #de0000;
`;

const Section = styled("p")`
  font-size: 1.2rem;
`;

const SectionBold = styled(Section)`
  font-weight: 700;
`;

const SectionRed = styled(Section)`
  color: #de0000;
`;

const Ol = styled("ol")`
  font-size: 1.2rem;
  li {
    margin-left: 2rem;
    margin-top: 1rem;
  }
`;

const Link = styled("a")`
  color: #0e72d7;
`;

export default function NfzInfoPage() {
  return (
    <>
      <Container>
        <br/>
        <TitleRed>
          Przeczytaj uważnie, jeśli chcesz korzystać z DokDok na NFZ.
        </TitleRed>

        <SectionBold>
          Jak zapisać się do przychodni DokDok i wypisać ze swojej
          dotychczasowej przychodni?
        </SectionBold>
        <Section>
          Aby korzystać z opieki lekarskiej w DokDok (przez aplikację 7 dni w
          tygodniu oraz stacjonarnie w dni robocze), musisz złożyć deklarację
          wyboru lekarza rodzinnego w DokDok.
        </Section>
        <SectionRed>
          Złożenie takiej deklaracji oznacza rezygnację z dotychczasowej
          przychodni na rzecz DokDok.
        </SectionRed>

        <br/>

        <Ol>
          Możesz to zrobić na kilka sposobów:
          <li>
            <b>elektronicznie</b>,{" "}
            <Link
              style={{color: "#0e72d7"}}
              href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER"
            >
              podpisując profilem zaufanym
            </Link>{" "}
            plik PDF o nazwie “deklaracja”, który przygotowaliśmy dla Ciebie
            podczas zakładania konta w aplikacji. Tak podpisany plik wyślij do
            nas na <Link href="mailto:dok@dokdok.pl">dok@dokdok.pl</Link>.
            <br/>
            <span style={{color: "#de0000"}}>
              {" "}
              Nie masz go już? Nie szkodzi, napisz na{" "}
              <a style={{color: "#de0000"}} href="mailto:dok@dokdok.pl">
                dok@dokdok.pl
              </a>{" "}
              i wyślemy Ci jeszcze raz wypełniony dokument.
            </span>
          </li>
          <li>
            <b>papierowo</b>, wysyłając do nas pocztą wydrukowaną i podpisaną
            deklarację. Nasz adres to al. Armii Krajowej 46ad, 50-541 Wrocław
          </li>
          <li>
            <b>osobiście w przychodni</b>, w ciągu 7 dni od założenia konta.
            Wydrukowany dokument czeka już na Ciebie.
          </li>
        </Ol>

        <br/>
        <br/>

        <SectionBold>Dlaczego warto to zrobić?</SectionBold>
        <Section>
          W DokDok zyskujesz szybki i bezproblemowy kontakt z lekarzem 7 dni w
          tygodniu przez aplikację mobilną. Możesz otrzymać e-receptę,
          e-skierowanie, e-zwolnienie. Po otrzymaniu skierowania od naszego
          lekarza, masz też dostęp do bezpłatnych badań diagnostycznych (których
          zakres określa NFZ). Oprócz tego przysługują Ci też wszystkie
          tradycyjne świadczenia lekarskie (jak w Twojej dotychczasowej
          przychodni), realizowane w naszych gabinetach przy al. Armii Krajowej
          46ad we Wrocławiu.
        </Section>

        <br/>
        <SectionBold>Czy DokDok jest dla mnie?</SectionBold>
        <Section>
          W DokDok zdalny kontakt z lekarzem i rezerwacja wizyt stacjonarnych
          odbywa się za pomocą smartfona i aplikacji mobilnej DokDok. Jeśli nie
          masz smartfona, pewnie lepiej poczujesz się w tradycyjnej przychodni.
          Natomiast jeśli zdalny kontakt z lekarzem wydaje Ci się
          niewystarczający — ponieważ korzystasz głównie z wizyt stacjonarnych w
          przychodni — warto wybrać placówkę która jest blisko Twojego miejsca
          zamieszkania. Jeśli zależy Ci głównie na dobrym kontakcie online —
          DokDok jest dla Ciebie!
        </Section>
      </Container>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
